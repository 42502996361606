import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { findModel } from "../shared/helper/findModelsHelper";
import {
  llmModelsNames,
  embeddingsModelNames,
} from "../shared/constants/modelsNames";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import ChipInput from "../shared/components/ChipInput";
import _ from "lodash";

const EditModal = ({
  typeModal,
  data,
  configs,
  open,
  onClose,
  onSave,
  agentNames,
  onDetailedSave,
  isEditObjectArrayConfig,
}) => {
  const [name, setName] = useState("");
  const { t } = useTranslation("config");
  const [types] = useState([
    {
      text: t("bool"),
      value: "bool",
    },
    {
      text: t("number"),
      value: "int",
    },
    {
      text: t("string"),
      value: "string",
    },
    {
      text: t("stringArray"),
      value: "sarray",
    },
    {
      text: t("objectArray"),
      value: "objectArray",
    },
  ]);
  const [type, setType] = useState("");
  const [model, setModel] = useState("");
  const [dirty, setDirty] = useState({});
  const [value, setValue] = useState("");
  const [valueArray, setValueArray] = useState("");
  const valueType = useMemo(() => {
    if (!type) {
      return null;
    }
    return types.find((x) => x.value === type);
  }, [types, type]);
  const embeddingModelValue = useMemo(
    () => findModel(embeddingsModelNames, model),
    [model],
  );
  const serviceValue = useMemo(() => findModel(llmModelsNames, model), [model]);
  const [errors, setErrors] = useState({});
  const getOptionTypeLabel = useMemo(() => (option) => option.text, []);

  useEffect(() => {
    if (typeModal !== "new") {
      const dataCopy = _.cloneDeep(data); // Hacer una copia profunda de data
      setName(dataCopy.name);
      setType(dataCopy.type);
      if (dataCopy.type === "sarray" || dataCopy.type === "objectArray") {
        setValueArray(dataCopy.value);
      } else {
        setValue(dataCopy.value);
        setModel(dataCopy.value);
      }

      setDirty({ name: false, type: false, value: false, valueArray: false });
    } else {
      if (isEditObjectArrayConfig) {
        setType("objectArray");
      } else {
        setType("");
      }
      setName("");
      setValue("");
      setValueArray([]);
      setDirty({});
    }
  }, [data, typeModal, open]);

  const validateUnique = (text, currentIndex) => {
    let indx;
    if (typeModal === "edit") {
      indx = data?.value.findIndex((item, index) => {
        return item.text === text && index !== currentIndex;
      });
    } else {
      indx = data?.value.findIndex((item) => {
        return item.text === text;
      });
    }

    return indx === -1 ? "" : t("modalErrorTextRepited");
  };

  const validate = useCallback(() => {
    const temp = {};
    temp.name = name !== "" ? "" : t("modalErrorNameRequired");
    let indx = configs?.findIndex((item) => {
      return item.name === name;
    });
    if (typeModal === "new" && temp.name === "" && indx !== -1) {
      temp.name = t("modalErrorNameRepited");
    }
    temp.type = type !== "" ? "" : t("modalErrorTypeRequired");
    let arrayIndex =
      typeModal === "edit" ? (data.rowIndex ? data.rowIndex : 0) : 0;
    switch (type) {
      case "sarray":
        temp.valueArray =
          valueArray.length !== 0 ? "" : t("modalErrorValueRequired");
        temp.value = "";
        break;
      case "objectArray":
        if (isEditObjectArrayConfig) {
          if (valueArray.length !== 0) {
            temp.valueArrayText =
              valueArray[arrayIndex].text === "" ||
              valueArray[arrayIndex].text === undefined
                ? t("modalErrorTextRequired")
                : "";
            if (temp.valueArrayText === "") {
              temp.valueArrayText = validateUnique(
                valueArray[arrayIndex].text,
                arrayIndex,
              );
            }
            temp.valueArrayAgent =
              !valueArray[arrayIndex].agent ||
              valueArray[arrayIndex].agent === null
                ? t("modalErrorAgentRequired")
                : "";
          } else {
            temp.valueArray = t("modalErrorValueRequired");
          }
          temp.name = "";
        } else {
          delete temp.valueArray;
        }
        temp.value = "";
        break;
      case "int":
        temp.valueArray = "";
        temp.value =
          value === ""
            ? t("modalErrorValueRequired")
            : isNaN(value)
            ? t("modalErrorValueHasToBeNumber")
            : value < 0
            ? t("modalErrorValueNegative")
            : "";
        break;
      case "bool":
        temp.valueArray = "";
        temp.value =
          value === ""
            ? t("modalErrorValueRequired")
            : value !== "false" && value !== "true"
            ? t("modalErrorValueHasToBoolean")
            : "";
        break;
      default:
        temp.valueArray = "";
        temp.value = value === "" ? t("modalErrorValueRequired") : "";
        break;
    }

    if (name === "self_service_agents_limit" && temp.value === "") {
      const maxAgents = data.client.maxAgents;
      temp.value = value < maxAgents ? t("modalErrorMaxAgent") + maxAgents : "";
    }
    if (name === "self_service_nodes_limit" && temp.value === "") {
      const maxNodes = data.client.maxNodes;
      temp.value = value < maxNodes ? t("modalErrorMaxNodes") + maxNodes : "";
    }
    if (!dirty.name && name !== "") {
      setDirty({ ...dirty, name: true });
    }
    if (!dirty.type && type !== "") {
      setDirty({ ...dirty, type: true });
    }
    if (!dirty.value && value !== "") {
      setDirty({ ...dirty, value: true });
    }
    if (!dirty.valueArray && valueArray !== "") {
      setDirty({ ...dirty, valueArray: true });
    }
    if (
      !dirty.valueArrayText &&
      valueArray.length !== 0 &&
      valueArray[0]?.text !== "" &&
      valueArray[0]?.text !== undefined
    ) {
      setDirty({ ...dirty, valueArrayText: true });
    }
    if (
      !dirty.valueArrayAgent &&
      valueArray.length !== 0 &&
      valueArray[0].agent &&
      valueArray[0].agent !== null
    ) {
      setDirty({ ...dirty, valueArrayAgent: true });
    }

    if (type) {
      if (types.find((x) => x.value === type) === undefined) {
        temp.transferName = t("modalErrorTransferNotExist");
      }
    }
    setErrors({ ...temp });
  }, [name, type, t, value, valueArray]);

  useEffect(() => {
    validate();
  }, [name, type, value, validate]);

  const handleTypeChange = useCallback(
    (event, newValue) => {
      if (newValue !== null) {
        setType(newValue.value);
      } else {
        setType("");
      }
    },
    [setType],
  );

  const handleModelChange = useCallback(
    (event, newValue) => {
      setValue(newValue ? newValue.id : "");
    },
    [setValue],
  );

  const handleOnChangeObjectArrayText = (e) => {
    if (typeModal === "new") {
      const newValueArray = [...valueArray];
      if (newValueArray.length === 0)
        newValueArray.push({ text: e.target.value });
      newValueArray[0].text = e.target.value;
      setValueArray(newValueArray);
    } else {
      const newValueArray = [...valueArray];
      newValueArray[data.rowIndex].text = e.target.value;
      setValueArray(newValueArray);
    }
  };

  const handleOnChangeObjectArrayAgent = (newValue) => {
    if (typeModal === "new") {
      const newValueArray = [...valueArray];
      if (newValueArray.length === 0) newValueArray.push({ agent: newValue });
      newValueArray[0].agent = newValue;
      setValueArray(newValueArray);
    } else {
      const newValueArray = [...valueArray];
      newValueArray[data.rowIndex] = {
        ...newValueArray[data.rowIndex],
        agent: newValue,
      };
      setValueArray(newValueArray);
    }
  };

  const handleClose = () => {
    setName("");
    setType("");
    setValue("");
    setValueArray([]);
    setDirty({});
    onClose();
  };

  const renderInputTypes = useMemo(
    () =>
      function renderInput(params) {
        return (
          <TextField
            variant="standard"
            {...params}
            label={
              params.id !== "objectArray-type"
                ? t("type") + "*"
                : t("agent") + "*"
            }
            error={
              (errors.type && dirty.type) ||
              (errors.valueArrayAgent && dirty.valueArrayAgent)
            }
            helperText={
              errors.type && dirty.type
                ? errors.type
                : errors.valueArrayAgent && dirty.valueArrayAgent
                ? errors.valueArrayAgent
                : ""
            }
          />
        );
      },
    [t, errors.type, dirty.type, errors.valueArrayAgent, dirty.valueArrayAgent],
  );

  const renderModelTypes = useMemo(
    () =>
      function renderModel(params) {
        return (
          <TextField
            variant="standard"
            {...params}
            label={t("model") + "*"}
            error={errors.type && dirty.type}
            helperText={errors.type && dirty.type ? errors.type : ""}
          />
        );
      },
    [t, errors.type, dirty.type],
  );

  const hasErrors = useMemo(
    () => Object.keys(errors).some((x) => errors[x] !== ""),
    [errors],
  );

  const handleSave = useCallback(() => {
    if (type === "objectArray" && valueArray.length !== 0) {
      onDetailedSave({ name, type, value, valueArray });
    } else {
      onSave({ name, type, value, valueArray });
    }
  }, [name, type, value, valueArray, onSave]);

  const RowEditComponent = () => {
    if (type === "sarray") {
      return (
        <ChipInput
          sx={{ width: "75%" }}
          name="value"
          source="value"
          label={t("value") + "*"}
          value={valueArray}
          updateProps={(e) => setValueArray(e)}
          fullWidthInput
          InputProps={{
            multiline: true,
            id: "chip-value",
            minRows: 1,
            maxRows: 99,
          }}
        />
      );
    }
    if (name === "external_ai_model") {
      return (
        <Autocomplete
          variant="standard"
          options={llmModelsNames}
          getOptionLabel={(option) => option.name}
          value={serviceValue}
          sx={{ width: "75%", marginTop: "2rem", marginBottom: "2rem" }}
          onChange={handleModelChange}
          renderInput={renderModelTypes}
        />
      );
    } else if (name === "embedding_model") {
      return (
        <Autocomplete
          variant="standard"
          options={embeddingsModelNames}
          getOptionLabel={(option) => option.name}
          value={embeddingModelValue}
          sx={{ width: "75%", marginTop: "2rem", marginBottom: "2rem" }}
          onChange={handleModelChange}
          renderInput={renderModelTypes}
        />
      );
    }
    if (type === "objectArray") {
      if (isEditObjectArrayConfig) {
        return (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              width: "80%",
              justifyContent: "center",
              padding: "10px",
            }}>
            <TextField
              id="objectArray-text"
              variant="standard"
              label={t("text") + "*"}
              value={
                valueArray.length !== 0
                  ? valueArray[data.rowIndex]?.text
                  : undefined
              }
              fullWidth
              error={errors.valueArrayText && dirty.valueArrayText}
              helperText={
                errors.valueArrayText && dirty.valueArrayText
                  ? errors.valueArrayText
                  : ""
              }
              onChange={(e) => handleOnChangeObjectArrayText(e)}
            />
            <Autocomplete
              sx={{ width: "100%" }}
              id="objectArray-type"
              variant="standard"
              options={agentNames ? agentNames : []}
              fullWidth
              value={
                valueArray.length !== 0
                  ? valueArray[data.rowIndex]?.agent
                  : undefined
              }
              onChange={(e, newValue) =>
                handleOnChangeObjectArrayAgent(newValue)
              }
              renderInput={renderInputTypes}
            />
          </div>
        );
      } else {
        return;
      }
    } else {
      return (
        <TextField
          id="modal-value"
          variant="standard"
          label={t("value") + "*"}
          sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
          value={value}
          error={errors.value && dirty.value}
          helperText={errors.value && dirty.value ? errors.value : ""}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="h6" gutterBottom sx={{ padding: "1rem" }}>
        {typeModal === "new" ? t("modalTitleNew") : t("modalTitleEdit")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        {!isEditObjectArrayConfig && (
          <>
            <TextField
              id="modal-name"
              variant="standard"
              label={t("name") + "*"}
              sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
              value={name}
              disabled={typeModal !== "new"}
              error={errors.name && dirty.name}
              helperText={errors.name && dirty.name ? errors.name : ""}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Autocomplete
              id="modal-type"
              variant="standard"
              options={types}
              disabled={typeModal !== "new"}
              getOptionLabel={getOptionTypeLabel}
              value={valueType}
              sx={{ width: "75%", marginTop: "2rem", marginBottom: "2rem" }}
              onChange={handleTypeChange}
              renderInput={renderInputTypes}
            />
          </>
        )}
        {RowEditComponent()}
      </Box>
      <DialogActions>
        <Button id="modal-cancel-button" onClick={handleClose}>
          {t("modalCancel")}
        </Button>
        <Button
          id="modal-save-button"
          variant="contained"
          color="primary"
          disabled={hasErrors}
          onClick={handleSave}>
          {t("modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
